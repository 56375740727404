import React, { useEffect, useRef, useState  } from "react";
import {connect} from 'react-redux';
import useStyles from "./styles";

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import PerfectScrollbar from 'react-perfect-scrollbar';
import useMutationObserver from './hooks/useMutationObserver';

import { treeFileUpload, setUploadTreeFile, setSearchHeight, setTreeHeight, searchCompany} from "../../../actions/patenTrackActions";



function CorporateTreeUploader(props) {
    const classes = useStyles();
    const formUploadRef = useRef();
    const frameRef = useRef();

    const [isMutationObserverActive, setIsMutationObserverActive] = useState(false);

    useEffect(() => {
        if(props.corporate_html_file != "") {  
           setTimeout(() => {
            const targetNode = document.getElementById('observedNode');
            const btn = document.createElement('button');
                  btn.innerText = "Save As";
                  btn.style = "position:absolute;top:20px;right:20px";
                  btn.addEventListener("click", function(){
                    const rawData = targetNode.innerHTML;
                    let filename = props.tree_file_name != '' ? props.tree_file_name : ".html";
                    let blob = new Blob([rawData], {
                        type: "application/html;charset=utf-8"
                    });
                    var element = document.createElement('a');
                    var url = URL.createObjectURL(blob);
                    element.href = url;
                    element.setAttribute('download', filename);
                    document.body.appendChild(element); 
                    element.click();
                    document.body.removeChild(element);
                  });
                targetNode.appendChild(btn)

            const allSpan = targetNode.querySelectorAll('span');
            
            allSpan.forEach(span => {                
                span.addEventListener("click", () => {
                    
                    (async () => {
                        await removeOtherClass(allSpan);
                        span.classList.add('active_tree');
                    })(); 
                    if(span.className != "" && (span.className.indexOf('selected') >= 0 || span.className.indexOf('unselected') >= 0 || span.className.indexOf('noe') >= 0)) {
                       
                        const companyName = span.innerText
                        const searchElement = document.getElementById('search_company')
                        searchElement.focus()
                        searchElement.value = companyName 
                        props.searchCompany(companyName)
                    }
                } ,false);
            });
            const removeOtherClass = async(allSpan) => {
                allSpan.forEach(span => {
                    if(span.classList.contains('active_tree')){
                        span.classList.remove('active_tree');
                    }
                })
            }
            const allCheckbox = targetNode.querySelectorAll('input[type="checkbox"]');
            allCheckbox.forEach(checkbox => {
                checkbox.addEventListener("click", (event) => {
                    console.log(event.target.checked);
                    event.target.setAttribute('checked', event.target.checked);
                } ,false);
            });
           }, 1000);
        }
    },[props.corporate_html_file]);

    const handleCloseTree = () => {
        props.setUploadTreeFile(false);
        props.setSearchHeight('100%');
        props.setTreeHeight('0%');
    }
/*
    useMutationObserver(isMutationObserverActive, mutations =>
        console.log('mutations', mutations)
    );*/

    const getChildData = childItems => {
        return childItems.map( childItemData => {
        let children = undefined;
        if (childItemData.child && childItemData.child.length > 0) {
            children = getChildData(childItemData.child);
        }
        const items = [];
        for(let i = 1; i <= childItemData.level; i++){
            items.push(<td className={i == childItemData.level ? '' : classes.width10}>{i == childItemData.level ? childItemData.name : ''}</td>)
        }
        return(
            <>
            <table className={classes.tableView}>
                <tbody>
                <tr>
                    {items}
                </tr>
                </tbody>
            </table>
            {
                childItemData.child.length > 0 ? getChildData(childItemData.child) : ''
            }
            </>
        )
        });
    };

    function CorporateTree(props){
        return(
            <div style={{overflow:'auto', height: props.height - 153}}>
                <table className={classes.tableView}>
                <tbody>
                    <tr>
                    <td className={classes.width10}></td>
                    <td className={classes.width10}></td>
                    <td>{props.data.name}</td>
                    </tr>
                </tbody>
                </table>
                {getChildData(props.data.child)}
            </div>
        )
    }

    return (
        <div
      className={classes.searchContainer}
        >
            <span  
                className     = {classes.switcher}
                onClick       = {handleCloseTree}><img src="/assets/images/inward_icon.svg" alt={''}/></span>
            <div
                className={classes.container}
            >
                <div className={classes.context}>                    
                    <div className={`search-list ${classes.scrollbar}`} >
                        <PerfectScrollbar
                            options={{
                            suppressScrollX: true,
                            minScrollbarLength: 20,
                            maxScrollbarLength: 25
                            }}
                        >
                            {
                                props.corporate_tree.length > 0
                                ?
                                <CorporateTree data={props.corporate_tree[0]} height={props.height} />
                                :
                                ''
                            }
                            {
                                props.corporate_html_file != ''
                                ?
                                
                                <div id={"observedNode"} dangerouslySetInnerHTML={{__html: props.corporate_html_file}} style={{height: props.height - 75,overflow: 'auto'}}></div>
                                :
                                ''
                            }
                        </PerfectScrollbar>
                    </div>
                </div>
            </div>
        </div>
    );
}


const mapStateToProps = state => {
    return {
        width: state.patenTrack.screenWidth,
        height: state.patenTrack.screenHeight,
        corporate_tree: state.patenTrack.corporate_tree,
        corporate_html_file: state.patenTrack.corporate_html_file,
        tree_file_name: state.patenTrack.tree_file_name
    }
};

const mapDispatchToProps = {
    treeFileUpload,
    setUploadTreeFile,
    setSearchHeight,
    setTreeHeight,
    searchCompany
};

export default connect(mapStateToProps, mapDispatchToProps)(CorporateTreeUploader);