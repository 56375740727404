import { makeStyles } from "@material-ui/styles";

export default makeStyles(theme => ({
  loaderContainer: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    fontSize: '3rem'
  }
}));